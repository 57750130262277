import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/components/prism-markup-templating.js";
import 'prismjs/components/prism-php'
import "../styles/prism.css";

function Code({ code, language }) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <div className="code-highlighter gatsby-highlight" data-language={language} style={{boxShadow: "5px 8px 24px rgba(0, 0, 0, 0.08)"}}>
      <pre className={`language-${language}`}>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
}

export default Code