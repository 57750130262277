import React from 'react'
import { Link, withPrefix } from 'gatsby';

import highlightStyles from "./highlight.module.scss"
import buttonStyles from "../button/button.module.scss"

function Highlight(props) {
  return (
    <section className={highlightStyles.highlight} style={{backgroundImage: `url(${withPrefix('/background.png')})`}}>
      <div className={highlightStyles.inner}>
        <h2 className={highlightStyles.title}>{props.title}</h2>
        <p className={highlightStyles.description}>{props.description}</p>
        <Link to="/docs" className={buttonStyles.btnMedium}>
          Get Started
        </Link>
      </div>
    </section>
  )
}

export default Highlight