import React from 'react'

import testimonialStyles from "./testimonial.module.scss"
import buttonStyles from "../button/button.module.scss"

import Code from "../../code";
import { Link } from "gatsby"

import RightArrow from "../../../assets/svg/icons/arrow-right.svg"

function Testimonial() {
  const code = `use App\\Gateway\\CreditCardDriver;
use Bazar\\Support\\Facades\\Gateway;

public function boot(): void
{
    Gateway::extend('credit-card', function ($app) {
        return new CreditCardDriver(
            $app['config']->get('services.creditcard')
        );
    });
}`;

  return (
    <section className={testimonialStyles.testimonial}>
      <div className={testimonialStyles.rowLeft}>
        <img className={testimonialStyles.img} src={'/screenshot.png'} alt="Screenshot of the admin area" />
        <div>
          <p className={testimonialStyles.subtitle}>Build-in Admin UI</p>
          <h2 className={testimonialStyles.title}>Nice and Clean Admin Dashboard</h2>
          <p className={testimonialStyles.description}>We provide a clean and straigthforward admin dash out of the box. You can easily customize to your own needs.</p>
          <Link to="/docs" className={buttonStyles.btnText}>
            Read more
            <RightArrow />
          </Link>
        </div>
      </div>
      <div className={testimonialStyles.rowRight}>
          <div>
            <p className={testimonialStyles.subtitle}>Flexible and Dynamic</p>
            <h2 className={testimonialStyles.title}>Adopt Your Own Solutions Quickly</h2>
            <p className={testimonialStyles.description}>Bazar provides a flexible and easily extensible system, following the good old Laravel conventions.</p>
          <Link to="/docs" className={buttonStyles.btnText}>
            Read more
            <RightArrow />
          </Link>
          </div>
          <Code code={code} language="php" />
      </div>
    </section>
  )
}

export default Testimonial
