import React from 'react'

import informationStyles from "./information.module.scss"
import Icon01 from "../../../assets/svg/icons/information-01.svg"
import Icon02 from "../../../assets/svg/icons/information-02.svg"
import Icon03 from "../../../assets/svg/icons/information-03.svg"
import Icon04 from "../../../assets/svg/icons/information-04.svg"

function Information() {
  return (
    <section className={informationStyles.information}>
      <div className={informationStyles.inner}>
        <div className={informationStyles.item}>
          <div className={informationStyles.icon}><Icon01 /></div>
          <div className={informationStyles.body}>
            <h2 className={informationStyles.title}>Strong Foundation, Agile Structure</h2>
            <p className={informationStyles.description}>The Bazar core comes with tax and discount management, shipping methods and payment gateways, cart handling and many more. Need more? You can write your own extensions easily.</p>
          </div>
        </div>
        <div className={informationStyles.item}>
          <div className={informationStyles.icon}><Icon02 /></div>
          <div className={informationStyles.body}>
            <h2 className={informationStyles.title}>Speed up Your E-Commerce</h2>
            <p className={informationStyles.description}>Bazar is a drop-in solution. This means, unlike many other e-commerce Laravel solutions, Bazar works as a package and not as a standalone Laravel application, so you can quickly install to your Laravel application using composer.</p>
          </div>
        </div>
        <div className={informationStyles.item}>
          <div className={informationStyles.icon}><Icon03 /></div>
          <div className={informationStyles.body}>
            <h2 className={informationStyles.title}>A Highly Customizable Platform</h2>
            <p className={informationStyles.description}>However Bazar comes with a default configuration setup, you can easily override with your own. Configure currencies, authorization flows, cart behavior and many more.</p>
          </div>
        </div>
        <div className={informationStyles.item}>
          <div className={informationStyles.icon}><Icon04 /></div>
          <div className={informationStyles.body}>
            <h2 className={informationStyles.title}>Quality Code, DRY Aspect</h2>
            <p className={informationStyles.description}>Bazar is trying to follow the widely used conventions for Laravel. This means the coding style and solutions may be familiar for many Laravel developers.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Information
