import React from "react"
import { Link } from "gatsby"

import Layout from '../components/ui/layout/layout'
import Hero from '../components/ui/hero/hero'
import Testimonial from '../components/ui/testimonial/testimonial'
import Information from '../components/ui/information/information'
import Highlight from '../components/ui/highlight/highlight'
import Header from '../components/ui/header/header'
import SEO from "../components/seo"

import buttonStyles from "../components/ui/button/button.module.scss"

function IndexPage() {
  return (
    <Layout>
      <SEO title="Bazar" description='Bazar is a powerful "headless" e-commerce system. Built on Laravel and Vue.' />
      <Header/>
      <Hero>
        <h1 className="section-hero__title">Thoughtful Laravel E-commerce</h1>
        <p className="section-hero__description">Bazar is a powerful "headless" e-commerce system. Built on Laravel and Vue.</p>
        <div className="section-hero__btns">
          <Link to="/docs#documentation" className={buttonStyles.btnMedium}>
            Documentation
          </Link>
          <Link to="/news" className={buttonStyles.btnGhostMedium}>
            News
          </Link>
        </div>
      </Hero>
      <main id="content">
        <Testimonial />
        <Highlight title="Already using Bazar?" description="Browse some first-party packages, like product addons or other payment gateways."/>
        <Information />
      </main>
    </Layout>
  )
}

export default IndexPage
